<template>
  <div class="app-container">
    <div>
      <div style="float:left;">
        <el-date-picker
          v-model="fromDate"
          type="date"
          style="margin-left: 4px"
          :format="'dd-MM-yyyy'"
          :placeholder="$t('employeeWorklog.fromDate')"
          name="date"
          :picker-options="pickerOptions"
          @input="handleFilterChanged($event)"
        />
        <el-date-picker
          v-model="untilDate"
          type="date"
          style="margin-left: 4px"
          :format="'dd-MM-yyyy'"
          :placeholder="$t('employeeWorklog.untilDate')"
          name="date"
          :picker-options="pickerOptions"
          @input="handleFilterChanged($event)"
        />
        <el-select
          v-model="employeeID"
          class="search-select"
          type="select"
          :clearable="true"
          style="margin-bottom: 10px"
          :placeholder="$t('employeeWorklog.selectEmployee')"
          @input="handleFilterChanged($event)"
        >
          <el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id" />
        </el-select>
        <editor-button
          :icon-only="false"
          :loading="loading"
          button-translation="route.exportExcel"
          action="primary"
          @click="downloadReport"
        />
      </div>
      <el-collapse-transition>
        <el-alert v-if="errorMsg" :title="errorMsg" type="error" :closable="false" />
      </el-collapse-transition>
    </div>
    <div ref="tableContainer">
      <el-table ref="worklogList" :data="items" border row-key="employeeID" fit :max-height="getMaxTableHeight()">
        <el-table-column :label="$t('employeeWorklog.employeeName')" prop="employeeName" width="400px">
          <template slot-scope="scope">
            <span>{{ scope.row['employeeName'] }}</span>
          </template>
        </el-table-column>

        <el-table-column :label="$t('employeeWorklog.totalHours')" prop="totalHours" width="150px">
          <template slot-scope="scope">
            <md-input v-model="scope.row['totalHoursFormatted']" type="text" :readonly="true"></md-input>
          </template>
        </el-table-column>

        <el-table-column :label="$t('employee.hourly_wage')" prop="hourlyWage" width="100px">
          <template slot-scope="scope">
            <md-input v-model="scope.row['hourlyWage']" type="currency" :readonly="true"></md-input>
          </template>
        </el-table-column>

        <el-table-column :label="$t('employeeWorklog.totalWage')" prop="totalWage" width="100px">
          <template slot-scope="scope">
            <md-input v-model="scope.row['totalWage']" type="currency" :readonly="true"></md-input>
          </template>
        </el-table-column>

        <el-table-column :label="$t('employeeWorklog.totalCosts')" prop="totalCosts" width="100px">
          <template slot-scope="scope">
            <md-input v-model="scope.row['totalCosts']" type="currency" :readonly="true"></md-input>
          </template>
        </el-table-column>

        <el-table-column :label="$t('employeeWorklog.days')" prop="amount" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row['days'] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import EditorButton from '@/components/crud/EditorButton';
import { toString } from '@/utils/date';
import MdInput from '../../components/MDinput';
import { ForceFileDownload } from '../../utils/fileDownload';

export default {
  name: 'EmployeeWorklog',
  components: {
    MdInput,
    EditorButton
  },
  data() {
    return {
      loading: false,
      loadedData: { items: [], total: 0 },
      errorMsg: '',
      employeeID: null,
      fromDate: new Date(),
      untilDate: new Date()
    };
  },
  computed: {
    listParams() {
      return {
        employeeID: this.employeeID,
        fromDate: toString(this.fromDate, 'YYYY-MM-DD'),
        untilDate: toString(this.untilDate, 'YYYY-MM-DD')
      };
    },
    items() {
      return this.loadedData.items;
    },
    paymentMethods() {
      return this.$store.getters['option/loadedOptions']('paymentMethod');
    },
    employees() {
      return this.$store.getters['employee/loadedItems'].filter(e => e.onlineYN);
    },
    pickerOptions() {
      return {
        disabledDate(givenDate) {
          const lastDate = new Date();
          const date = new Date(givenDate);
          return date > lastDate;
        },
        firstDayOfWeek: 1
      };
    }
  },
  created() {
    this.$store.dispatch('employee/getItems');
    this.refreshList();
  },
  methods: {
    refreshList() {
      this.loading = true;
      this.$store
        .dispatch('employeeWorklog/getWorkedHoursReport', this.listParams)
        .then(data => {
          this.loadedData = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadReport() {
      this.loading = true;
      this.$store
        .dispatch('employeeWorklog/downloadWorkedHoursReport', this.listParams)
        .then(blob => {
          const fromDate = toString(this.fromDate || new Date(), 'DD-MM-YYYY');
          const untilDate = toString(this.untilDate || new Date(), 'DD-MM-YYYY');
          const excelName = `${this.$i18n.t('route.menu_employeeworklog_report')} ${fromDate}-${untilDate}.xlsx`;
          ForceFileDownload(blob, excelName);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleFilterChanged() {
      this.refreshList();
    },
    getMaxTableHeight() {
      if (this.$refs.tableContainer) {
        return window.innerHeight - this.$refs.tableContainer.getBoundingClientRect().top - 100;
      }
    }
  }
};
</script>
